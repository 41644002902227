<template>
  <div class="menu">
    <div v-if="platform === 'mobile'">
      <div class="pic">
        <img :src="logo" style="transform: scale(0.8); position:fixed; margin-top: -50px; left: 0%; z-index: 1"   width="100%">
      </div>
    </div>

    <!-- QR Code -->
    <div v-if="platform === 'desktop'">
      <div style="border: 1px solid #cbced3; display: inline-block; padding: 20px; padding-bottom: 12px; margin-top: 40px; width: 200px;">
        <qrcode-vue :value="qrcode" size="150" level="H"></qrcode-vue>
      </div>
      <div style="margin-top: 15px;">
        {{ $t('Login.no_service') }}<br>{{ $t('Login.scan_code') }}
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapMutations, mapGetters, mapActions, createNamespacedHelpers } from 'vuex'
import QrcodeVue from 'qrcode.vue'
const emc = createNamespacedHelpers('emc');


export default {
  data () {
    return {
      shopcontent: '',
      hostname: window.location.host,
      // whiteList: [],
      whiteList: ['U48fa1a40af1bee15102b06dd402be26e','U34c3dd0cf3a17a4177c0e15efd9420a5','U1cc83c76f29b62d9141fc7f6c7053e29','Utest', 'U7b6d287d630f71666adca971726756ac' , 'U4d0f9c5b607115debefe303baabb4f71'  , 'U12cd87201b3aff4a062004d920ec482'  , 'Uac1e77d862ffaaab19957607865b635c',],
      channelId: '',
      platform: 'mobile',
      logo: '',
      channelData: {
        AuthToken: this.$route.query.Auth,
        Service: this.$route.params.service === 'FB' ? 'FB' : 'LINE',
        PhoneNumber: '',
        Name: this.$route.query.Name ? this.$route.query.Name : '',
        PageID: this.$route.query.Page,
        Img: '',
        date: this.$route.query.date
      },
    }
  },
  components: {
    QrcodeVue
  },
  mounted() {
    const querystr = this.fixedString(window.location.search);
    //console.log(querystr);
    // const querystring = window.location.search;
    this.channelId = this.checkParam('code')
    // console.log(localStorage.getItem('channelId'));
    if (localStorage.getItem('channelId') === this.channelId ) {
      this.logo = localStorage.getItem('logo')
      this.color = localStorage.getItem('brandColor')
    }
    // localStorage.removeItem()
    this.identify()
  },
  computed: {
    ...mapGetters({
      eilisProfile: 'eilisProfile',
      info: 'info',
      personalData: 'personalData',
      brandData: 'brandData',
      brandColor: 'brandColor',
      aisleName: 'aisleName',
      azureShopList: 'azureShopList',
      paymentStatus: 'paymentStatus',
      shopDetail: 'shopDetail'
    }),
    storeShopContent: {
      get () { return this.shopDetail },
      set(value) { this.updateShopContent({ shopDetail: value }); },
    },
    storePaymentStatus: {
      get () { return this.paymentStatus },
      set(value) { this.updatePaymentStatus({ paymentStatus: value }); },
    },
    confirmComponent: {
      get () { return this.aisleName },
      set(value) { this.storeAisleName({ aisleName: value }); },
    },
    PData: {
      get () { return this.personalData },
      set(value) { this.storepersonal({ personal: value }); },
    },
    EFile: {
      get () { return this.eilisProfile },
      set(value) { this.storeEilisFile({ Efile: value }); },
    },
    storeBrandData: {
      get () { return this.brandData },
      set(value) { this.updateBrandData({ brand: value }); },
    },
    color: {
      get () { return this.brandColor },
      set(value) { this.updateBrandColor({ BColor: value }); },
    },
    azure: {
      get () { return this.azureShopList },
      set(value) { this.storeAzureShopList({ azureShopList: value }); },
    },
  },
  methods: {
    ...mapMutations({
      storeEilisFile: 'storeEilisFile',
      updateBrandData: 'updateBrandData',
      storepersonal: 'storepersonal',
      updateBrandColor: 'updateBrandColor',
      storeAisleName: 'storeAisleName',
      storeAzureShopList: 'storeAzureShopList',
      updatePaymentStatus: 'updatePaymentStatus',
      updateShopContent: 'updateShopContent'
    }),
    ...mapActions({
      sendCode: 'sendCode',
      Decrypt: 'Decrypt',
      getConfig: 'getConfig',
      getImages: 'getImages',
      checkUserByToken: 'checkUserByToken',
      setAccount: 'setAccount',
      getregConfig: 'getregConfig',
      Encrypt: 'Encrypt',
      Decrypt: 'Decrypt',
      getAzureBrandList: 'getAzureBrandList'
    }),
    // 修正加密參數
    fixedString(str) {
      return str.replace(/%3D/g, '=').replace(/%26/g, '&').replace(/%3F/g, '?').replace(/%2B/g, '+').replace(/%2F/g, '/').replace(/%20/g, '+').replace(/%3D/, '?');
    },
    month(val) {
      if (val < 10) {
          return '0'+ val;
      } else {
          return val
      }
    },
    date(val) {
      if (val < 10) {
          return '0'+ val;
      } else {
          return val
      }
    },
    checkParam(str) {
    const querystr = this.fixedString(window.location.search);
    console.log(querystr);
    // 判斷字串
    if (querystr.indexOf('aisle') !== -1) {
      if (str === 'aisle') {
        return decodeURIComponent(decodeURI(querystr.split(`${str}=`)[1]))
      } else {
        return querystr.split(`&${str}=`)[1].split('/')[0];
      }
    }
    else {
      if (str === 'shop') {
        // console.log(querystr.split(`${str}=`)[1]);
        // console.log(`163_${querystr.split(`&${str}=`)[1]}`);

        return querystr.split(`${str}=`)[1]
      }
      if (querystr.split(`&${str}=`)[1]) {
        return querystr.split(`&${str}=`)[1].split('&')[0];
        }
      else if (querystr.split(`?${str}=`)[1]) {
        return querystr.split(`?${str}=`)[1].split('&')[0];
        }
      else {
        return '';
        }
    }
    },
    decode(str) {
      this.Decrypt(str)
      .then((res) => {
        console.log(res);
        this.shopcontent = JSON.parse(res)
        console.log(`185:${this.shopcontent}`)
        this.storeShopContent = this.shopcontent
        if (!localStorage.getItem('shopcontent')) {
          localStorage.setItem('shopcontent' ,JSON.stringify(this.shopcontent))
        }
      })
    },
    getBrandData() {
      let code = this.checkParam('code')
      let aisle = this.checkParam('aisle')
      let status = this.checkParam('status')
      let shop = this.checkParam('shop')
      console.log(code);
      console.log(status);
      this.storePaymentStatus = status
      // console.log(code);
      // console.log(aisle);
      // this.channelId = this.fixedString(querystring.split('&code=')[1].split('&liff')[0]);
      // console.log(this.channelId);
      // this.confirmComponent = this.fixedString(querystring.split('&code=')[1]);
      // this.confirmComponent = decodeURIComponent(decodeURI(this.confirmComponent.split('aisle=')[1]))
      this.channelId = code
      this.confirmComponent = aisle
      // console.log('142-'+ this.confirmComponent);
      this.sendCode({code: this.channelId})
      .then((res) => {
        console.log(res);
        if (res.data.status === 10020 && res.data.result) {
          const A =  this.getConfig({accountId: this.eilisProfile.accountId})
          const B =  this.Decrypt(res.data.result)
          const C =  this.Decrypt(localStorage.getItem('personalInfo'))
          Promise.all([A, B, C]).then(values => {
            console.log(this.$route.query.Auth);
            console.log(values[0]); // 自己的該牌
            console.log('119');
            console.log(JSON.parse(values[1])); // 這個品牌
            console.log(values[0].data.result.auth === JSON.parse(values[1]).auth);
            console.log(JSON.parse(values[2]));
            this.$loading.hide()
            this.logo = JSON.parse(values[1]).logo;
            // 本地端個人品牌ID不等於目前品牌ID的條件
            if (values[0].data.result.auth !== JSON.parse(values[1]).auth) {
              localStorage.removeItem('personalInfo')
              localStorage.removeItem('eiAuth')
              localStorage.removeItem('logo')
              localStorage.removeItem('brandColor')
              localStorage.removeItem('channelId')
              this.getConfig({accountId: JSON.parse(values[1]).accountId})
              .then((res) => {
                console.log(res);
                this.storeBrandData = res.data.result
                // 聯發專屬
                if (this.storeBrandData.azure && this.storeBrandData.azure === true) {
                  this.getAzureBrandList({accountId: JSON.parse(values[1]).accountId})
                  .then((res) => {
                    console.log(res);
                    this.azure = res.data.result
                  })
                }
                localStorage.setItem('brandColor', this.storeBrandData.brandColor)
                localStorage.setItem('channelId', this.channelId)
              })
              if (this.channelData.Service === 'FB' || (this.whiteList.indexOf(this.$route.query.Auth ) !== -1 || hostname.indexOf('localhost') !== -1)) {
                this.EFile = JSON.parse(values[1])
                this.Login()
              } else {
                this.EFile = JSON.parse(values[1])
                liff.init({
                  liffId: JSON.parse(values[1]).liffId
                })
                .then(() => {
                liff.getProfile()
                  .then((profile) => {
                    console.log(liff);
                    this.channelData.AuthToken = profile.userId;
                    // localStorage.setItem('profileImg', profile.pictureUrl)
                    this.Login()
                    // this.$router.push(`/mainbottom/${this.channelData.Service}?Auth=${this.channelData.AuthToken}`)
                  })
                })
                .catch((err) => {
                  this.$createDialog({
                    type: 'alert',
                    showClose: false,
                    title: '系統提示',
                    content: '142'+'請更新您的LINE版本或重新登入',
                    // text: '你的訂單已成功送出，請前往訂單查詢查看',
                    confirmBtn: {
                      text: '確定',
                      active: true,
                      disabled: false,
                    },
                  }).show()
                })
              }
            // 本地端個人品牌ID等於目前品牌ID的條件
            } else {
              // console.log('173');
              this.storeBrandData = values[0].data.result
              this.EFile = JSON.parse(values[1])
              this.PData = JSON.parse(values[2])
              // console.log('169');
              console.log(this.EFile);
              // return;
              if (this.channelData.Service === 'FB' || (this.whiteList.indexOf(this.$route.query.Auth ) !== -1 || hostname.indexOf('localhost') !== -1)) {
                this.$router.push(`/mainbottom/${this.channelData.Service}?Auth=${this.channelData.AuthToken}`)
              } else {
                liff.init({
                  liffId: JSON.parse(values[1]).liffId
                })
                .then(() => {
                liff.getProfile()
                  .then((profile) => {
                    console.log(profile);
                    this.channelData.AuthToken = profile.userId;
                    this.$router.push(`/mainbottom/LINE?Auth=${this.channelData.AuthToken}`)
                  })
                })
                .catch((err) => {
                  this.$createDialog({
                    type: 'alert',
                    showClose: false,
                    title: '系統提示',
                    content: '142'+'請更新您的LINE版本或重新登入',
                    // text: '你的訂單已成功送出，請前往訂單查詢查看',
                    confirmBtn: {
                      text: '確定',
                      active: true,
                      disabled: false,
                    },
                  }).show()
                })
              }
            }
          })
        }
      })
    },
    preLogin() {
      // console.log(this.EFile);
      if (this.EFile.length > 0) {
        this.Login()
      } else {
        let code = this.checkParam('code')
        let aisle = this.checkParam('aisle')
        let status = this.checkParam('status')
        let shop = this.checkParam('shop')
        console.log(shop);
        if (shop) {
          this.decode(shop)
        }
        console.log(code);
        console.log(status);
        console.log(shop);
        this.storeShopContent = shop
        this.storePaymentStatus = status
        // console.log(code);
        // console.log(aisle);
        // this.channelId = this.fixedString(querystring.split('&code=')[1].split('&liff')[0]);
        // console.log(this.channelId);
        // this.confirmComponent = this.fixedString(querystring.split('&code=')[1]);
        // this.confirmComponent = decodeURIComponent(decodeURI(this.confirmComponent.split('aisle=')[1]))
        this.channelId = code
        this.confirmComponent = aisle
        this.sendCode({code: this.channelId})
        .then((res) => {
          // console.log(res);
          if (res.data.status === 10020 && res.data.result) {
            // console.log(res.data.result);
            this.Decrypt(res.data.result)
            .then((res) => {
              console.log(JSON.parse(res));
              this.$loading.hide()
              this.EFile = JSON.parse(res);
              this.logo = JSON.parse(res).logo;
              // this.clientId = JSON.parse(res).auth
              this.Login();
            })
          }
        })
      }
    },
    LiffLogin() {
      if (this.EFile.length > 0) {
        this.Login()
      } else {
        let code = this.checkParam('code')
        let aisle = this.checkParam('aisle')
        let status = this.checkParam('status')
        let shop = this.checkParam('shop')
        console.log(shop);
        if (shop) {
          this.decode(shop)
        }
        console.log(code);
        console.log(status);
        console.log(shop);
        this.storeShopContent = shop
        this.storePaymentStatus = status
        // console.log(code);
        // console.log(aisle);
        // this.channelId = this.fixedString(querystring.split('&code=')[1].split('&liff')[0]);
        // console.log(this.channelId);
        // this.confirmComponent = this.fixedString(querystring.split('&code=')[1]);
        // this.confirmComponent = decodeURIComponent(decodeURI(this.confirmComponent.split('aisle=')[1]))
        this.channelId = code
        this.confirmComponent = aisle
        // console.log(channelId);
        this.sendCode({code: this.channelId})
        .then((res) => {
          console.log(res);
          if (res.data.status === 10020 &&  res.data.result) {
            console.log(res.data.result);
            this.Decrypt(res.data.result)
            .then((res) => {
              console.log(JSON.parse(res));
              this.$loading.hide()
              this.EFile = JSON.parse(res);
              this.logo = JSON.parse(res).logo;
              liff.init({
                liffId: JSON.parse(res).liffId
              })
              .then(() => {
              liff.getProfile()
                .then((profile) => {
                  this.channelData.AuthToken = profile.userId;
                  console.log(this.channelData.AuthToken);
                  this.channelData.Img = profile.pictureUrl;
                  this.Login();
                })
              })
              .catch((err) => {
                alert(err);
                //alert('已離開會員卡。');
                //liff.closeWindow();
              })
            })
          }
        })
      }
    },
    identify() {
      if ( /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        this.platform = 'mobile';
        this.$loading.show()
        this.$loading.hide()
        //U4d0f9c5b607115debefe303baabb4f71
        if (this.channelData.Service === 'FB' || (this.whiteList.indexOf(this.$route.query.Auth ) !== -1 || hostname.indexOf('localhost') !== -1 )) {
          if (localStorage.getItem('personalInfo') && localStorage.getItem('eiAuth')) {
            this.getBrandData()
          } else {
            this.preLogin()
          }
        }
        else {
          if (localStorage.getItem('personalInfo') && localStorage.getItem('eiAuth')) {
            this.getBrandData()
          } else {
            this.LiffLogin();
          }
        }
      } else {
        this.platform = 'desktop';
        if (this.channelData.Service === 'FB') {
          this.qrcode = `https://liff.line.me/${this.eilisProfile.liffId}`;
        } else {
          this.qrcode = `https://liff.line.me/${this.eilisProfile.liffId}`;
        }
      }
    },
    Login() {
      console.log(this.eilisProfile);
      console.log(this.eilisProfile.auth);
      console.log(this.channelData.AuthToken);
      console.log(this.channelData.Service);
      this.checkUserByToken({ service: this.channelData.Service, token: this.channelData.AuthToken, auth: this.eilisProfile.auth, accountId: this.eilisProfile.accountId})
      .then((res) => {
        console.log(res);
        // return;
        // this.platform = 'mobile';
        // console.log(Object.values(res.data));
        if (res.data.result === null) {
          // 貯存當下品牌的網址code
          localStorage.setItem('channelId', this.channelId)
          this.$router.push(`/initial/${this.channelData.Service}?Auth=${this.channelData.AuthToken}&Img=${this.channelData.Img}`)
          this.loading = false;
        } else if (res.data.result.name) {
          // 中間刪除，QRCODE進入店家會出錯
          let shop = this.checkParam('shop')
          console.log(shop);
          if (shop) {
            this.decode(shop)
          }
          // 中間刪除，QRCODE進入店家會出錯

          // 加密暫存個人資料
          this.Encrypt(res.data.result).then((res) => {
            localStorage.setItem('personalInfo', res)
            localStorage.setItem('eiAuth',this.eilisProfile.auth)
            localStorage.setItem('logo', this.logo)
          })
          this.PData = res.data.result;
          if (this.storeBrandData.length === 0) {
            this.getConfig({accountId: this.eilisProfile.accountId})
            .then((res) => {
              console.log(res);
              if (res.data.status === 10020) {
                this.storeBrandData = res.data.result
                // 聯發專屬
                if (this.storeBrandData.azure && this.storeBrandData.azure === true) {
                  this.getAzureBrandList({accountId: this.eilisProfile.accountId})
                  .then((res) => {
                    console.log(res);
                    this.azure = res.data.result
                  })
                }
                localStorage.setItem('brandColor', this.storeBrandData.brandColor)
                localStorage.setItem('channelId', this.channelId)
                this.$router.push(`/mainbottom/${this.channelData.Service}?Auth=${this.channelData.AuthToken}`)
              }
            })
          } else {
            this.$router.push(`/mainbottom/${this.channelData.Service}?Auth=${this.channelData.AuthToken}`)
          }
        } else if (res.data.Message === '查無此用戶') {
          this.loading = false;
        }
      })
      .catch((err) => {
        this.loading = false;
        alert(err)
      })
    },
    // downloadIamge(imgsrc, name) {//下载图片地址和图片名
    //   console.log(imgsrc);
    //   var src = imgsrc
    //   // src = src + 'png'
    //   // header: {'Content-Type: application/javascript'}
    //  var param = {
    //     header: {
    //       "content-type": "application/javascript" // 豆瓣一定不能是json
    //     }
    //   };
    //   this.$jsonp(src ,param).then(
    //     (json) => {
    //       console.log(json)
    //     }).catch(err => {
    //       console.log(err)
    //     })
      // let image = new Image();
      // // 解决跨域 Canvas 污染问题
      // image.setAttribute("crossOrigin", "anonymous");
      // image.onload = function() {
      //   let canvas = document.createElement("canvas");
      //   canvas.width = image.width;
      //   canvas.height = image.height;
      //   let context = canvas.getContext("2d");
      //   context.drawImage(image, 0, 0, image.width, image.height);
      //   let url = canvas.toDataURL("image/png"); //得到图片的base64编码数据
      //   let a = document.createElement("a"); // 生成一个a元素
      //   let event = new MouseEvent("click"); // 创建一个单击事件
      //   a.download = name || "photo"; // 设置图片名称
      //   let blob = new Blob([data], {
      //       type: 'application/javascript',
      //     });
      //   a.href = url(blob); // 将生成的URL设置为a.href属性
      //   a.dispatchEvent(event); // 触发a的单击事件
      // };
      // image.src = imgsrc;
    // },
  }
}
</script>

<style lang="scss" scoped>
      /* Absolute Center Spinner */
  .loadingIcon {
    display: flex; // 置中
    align-items: center; // 置中
    justify-content: center; // 置中
    margin: 0 auto;
    position: fixed;
    margin: 0 auto;
    position: fixed;
    top: 70%;
    left: 0;
    right: 0;
  }
  .menu {
    padding-left: 0!important;
    padding-right: 0!important;
    margin: 0 auto;
    overflow: hidden;
    width: 100%;
    max-width: 540px;
    min-width: 300px;
    height: 750px;
    background-color: #ffffff;
    /* border: 1px solid rgb(170, 170, 170); */
    /* border:1px solid #8a8a8a; */
  }
  .pic {
    position: fixed;
    z-index: 10000;
    margin-top: 35%;
    margin-left: 10px;
    -webkit-animation-name: myframes;
            animation-name: myframes;
    -webkit-animation-duration: 300ms;
            animation-duration: 300ms;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
    -webkit-animation-delay: 0s;
            animation-delay: 0s;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
    -webkit-animation-direction: normal;
            animation-direction: normal;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
  }
.box.is-paused {
  -webkit-animation-play-state: paused;
          animation-play-state: paused;

}
@keyframes myframes {
  0% {
    // -webkit-transform: scale(1) translateX(200px) rotate(0deg);
    //         transform: scale(1) translateX(200px) rotate(0deg);
    opacity: 0.1;
  }
  // 50% {
  //   -webkit-transform: scale(0.5) rotate(0deg);
  //           transform: scale(0.5) rotate(0deg);
  //   opacity: 0.5;
  // }
  // 80% {
  //   -webkit-transform: scale(0.8) rotate(0deg);
  //           transform: scale(0.8) rotate(0deg);
  //   opacity: 0.8;
  // }
  100% {
    // -webkit-transform: scale(1) translateX(0px) rotate(0deg);
    //         transform: scale(1) translateX(0px) rotate(0deg);
    opacity: 1;
  }
}
</style>
